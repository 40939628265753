import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { loginUser, resetStaffPassword } from "../../../_actions/user_actions";
import { Formik } from 'formik';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import * as Yup from 'yup';
import { Form, Input, Button, Checkbox, Typography, message } from 'antd';
import { useDispatch } from "react-redux";

const { Title } = Typography;

function LoginPage(props) {
  const dispatch = useDispatch();
  const rememberMeChecked = localStorage.getItem("rememberMe") ? true : false;

  const [formErrorMessage, setFormErrorMessage] = useState('')
  const [rememberMe, setRememberMe] = useState(rememberMeChecked)

  const handleRememberMe = () => {
    setRememberMe(!rememberMe)
  };

  const handleReset = (email) => {
    dispatch(resetStaffPassword({"_id": email})).then(response => {
      console.log(response)
      if (response.payload) {
        message.error(response.payload)
      }
      else message.success("If your email is valid you should have a new password in your inbox. The message may be in your spam filter")
    })
  }

  const initialEmail = localStorage.getItem("rememberMe") ? localStorage.getItem("rememberMe") : '';

  return (
    <Formik
      initialValues={{
        email: initialEmail,
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .min(4, "Must be at least 4 characters")
          .matches(/@/, "Must contain '@' symbol")
          .required('Email or User Name is required'),
        password: Yup.string()
          .min(6, 'Password must be at least 6 characters')
          .required('Password is required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        let dataToSubmit = {
          user: values.email.toLowerCase(),
          passwd: values.password
        };

        dispatch(loginUser(dataToSubmit))
          .then(response => {
            if (response.payload.message) {
              message.error(response.payload.message)
            }
              window.localStorage.setItem('userId', response.payload.userId);
              if (rememberMe === true) {
                window.localStorage.setItem('rememberMe', values.id);
              } else {
                localStorage.removeItem('rememberMe');
              }
              props.history.push("/");
              setSubmitting(false);
          })
          .catch(err => {
            setFormErrorMessage('Check out your Account or Password again')
            setTimeout(() => {
              setFormErrorMessage("")
            }, 3000);
          });
      }}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;
        return (
          <div className="app">

            <Title level={2}>Log In</Title>
            <form onSubmit={handleSubmit} style={{ width: '350px' }}>

              <Form.Item required>
                <Input
                  id="email"
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Enter your email or User Name"
                  type="username"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email ? 'text-input error' : 'text-input'
                  }
                />
                {errors.email && touched.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </Form.Item>

              <Form.Item required>
                <Input
                  id="password"
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Enter your password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.password && touched.password ? 'text-input error' : 'text-input'
                  }
                />
                {errors.password && touched.password && (
                  <div className="input-feedback">{errors.password}</div>
                )}
              </Form.Item>

              {formErrorMessage && (
                <label ><p style={{ color: '#ff0000bf', fontSize: '0.7rem', border: '1px solid', padding: '1rem', borderRadius: '10px' }}>{formErrorMessage}</p></label>
              )}

              <Form.Item>
                <Checkbox id="rememberMe" onChange={handleRememberMe} checked={rememberMe} >Remember me</Checkbox>
                <a className="login-form-forgot" onClick={() =>{handleReset(values.email)}} style={{ float: 'right' }}>
                  Staff Forgot Password
                  </a>
                <div>
                  <Button type="primary" htmlType="submit" className="login-form-button" style={{ minWidth: '100%' }} disabled={isSubmitting} onSubmit={handleSubmit}>
                    Log in
                </Button>
                </div>
                Or <a href="/register">Register Now!</a>
              </Form.Item>
            </form>
            <PrivacyPolicyLink />
            <UpdateLink />
          </div>
        );
      }}
    </Formik>
  );
};

const PrivacyPolicyLink = () => (
  <a href="/privacy-policy" className="privacy-policy-link">
    Privacy Policy
  </a>
);
const UpdateLink = () => (
  <a href="/updates" className="updates-link">
    Updates
  </a>
);

export default withRouter(LoginPage);


